<template>
  <!------ Upcoming Courses ------>
  <div class="letwecare-lessions-wrapper" :style="{ marginBottom: '40px' }">
    <!-- Courses Header -->
    <div class="lessions__header">
      <a-row>
        <a-col :span="24">
          <div class="header-title">
            <carry-out-outlined /> 即將上課
          </div>
        </a-col>
      </a-row>
    </div>
    <!-- Course Header -->

    <!-- Courses Content -->
    <div class="courses__content">
      <template v-if="Object.keys(upcomingData).length > 0">
        <a-card
          class="letwacare-lession-card"
          @click="device.mobile ? toLessionPage(upcomingData.id) : null"
          :bordered="false"
        >
          <a-row type="flex" :gutter="device.mobile ? 12 : 16">
            <a-col :flex="device.mobile ? '100px' : '150px'">
              <div class="card-date">
                <div class="date-title">{{ upcomingData.start_datetime_formated.date }}</div>
                <div class="date-label">{{ upcomingData.start_datetime_formated.year }} / {{ upcomingData.start_datetime_formated.month }}</div>
              </div>
            </a-col>
            <a-col flex="8">
              <div class="card-content">
                <div class="content-title">{{ upcomingData.name }}</div>
                <div class="content-description">{{ upcomingData.description }}</div>
              </div>
            </a-col>
            <a-col flex="2" v-show="!device.mobile">
              <div class="card-actions">
                <a-button type="primary" size="large" @click="toLessionPage(upcomingData.id)">查看課堂</a-button>
              </div>
            </a-col>
          </a-row>
        </a-card>
      </template>

      <template v-else>
        <a-empty :image="simpleImage" description="沒有課堂" />
      </template>
    </div>
    <!-- Courses Content -->
  </div>
  <!------ Upcoming Courses ------>

  <!------ All Courses ------>
  <div class="letwecare-lessions-wrapper">
    <!-- Courses Header -->
    <div class="lessions__header">
      <a-row>
        <a-col :span="12">
          <div class="header-title">
            <calendar-outlined />
            所有課堂
          </div>
        </a-col>

        <a-col :span="12" align="right">
          <div class="header-actions">
            <a-select
              v-model:value="filterValue"
              :options="filterOptions"
            ></a-select>
          </div>
        </a-col>
      </a-row>
    </div>
    <!-- Course Header -->

    <!-- Courses Content -->
    <div class="courses__content">
      <template v-if="filteredData.length > 0">
        <a-card
          class="letwacare-lession-card"
          v-for="(lession, lessionIdx) in filteredData"
          @click="device.mobile ? toLessionPage(lession.id) : null"
          :bordered="false"
        >
          <a-row type="flex" :gutter="device.mobile ? 12 : 16">
            <a-col :flex="device.mobile ? '100px' : '150px'">
              <div
                v-if="upcomingData.id == lession.id"
                class="card-tag"
              >
                <a-tag color="blue">即將上課</a-tag>
              </div>

              <div class="card-date">
                <div class="date-title">{{ lession.start_datetime_formated.date }}</div>
                <div class="date-label">{{ lession.start_datetime_formated.year }} / {{ lession.start_datetime_formated.month }}</div>
              </div>
            </a-col>
            <a-col flex="8">
              <div class="card-content">
                <div class="content-title">{{ lession.name }}</div>
                <div class="content-description">{{ lession.description }}</div>
              </div>
            </a-col>
            <a-col flex="2" v-show="!device.mobile">
              <div class="card-actions">
                <a-button type="primary" size="large" @click="toLessionPage(lession.id)">查看課堂</a-button>
              </div>
            </a-col>
          </a-row>
        </a-card>
      </template>

      <template v-else>
        <a-empty :image="simpleImage" description="沒有課堂" />
      </template>
    </div>
    <!-- Courses Content -->
  </div>
  <!------ All Courses ------>
</template>

<script>
import { defineComponent, ref } from 'vue'
import moment from 'moment'
import { Empty } from 'ant-design-vue'
import { CarryOutOutlined, CalendarOutlined } from '@ant-design/icons-vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import { useAuthStore } from '@/store/auth'

export default defineComponent({
  props: ['data', 'upcomingData'],
  components: {
    CarryOutOutlined,
    CalendarOutlined
  },
  setup() {
    const device = createDeviceDetector();
    const authStore = useAuthStore();

    return {
      device,
      authStore,

      /* Filter options */
      filterOptions: ref([{
        value: 'all',
        label: '所有課堂'
      }, {
        value: 'unstarted',
        label: '未開課'
      }, {
        value: 'finished',
        label: '已結束'
      }]),
      filterValue: ref('all'),

      /* Empty */
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  },
  computed: {
    filteredData() {
      let data = [];
      
      if (this.filterValue == 'all') data = this.data;
      else if (this.filterValue == 'unstarted') {
        this.data.forEach(item => {
          if (moment().isBefore(item.start_date)) {
            data.push(item);
          }
        });
      }
      else if (this.filterValue == 'finished') {
        this.data.forEach(item => {
          if (moment().isAfter(item.end_date)) {
            data.push(item);
          }
        })
      }

      return data
    },
  },
  methods: {
    /* Router */
    toLessionPage(lession_id) {
      this.$router.push({
        name: 'AppCourseLessionPage',
        params: {
          course_id: this.$route.params.course_id,
          lession_id: lession_id
        }
      });
    }
  }
})
</script>