<template>
  <!-- Layout Header -->
  <a-layout-header class="layout-header" :style="{ height: device.mobile ? '50px' : '74px' }">
    <a-row>
      <a-col :lg="12" :xs="21">
        <a-button
          class="header-menu-btn"
          type="link" shape="circle"
          @click="appStore.collapsed=!appStore.collapsed"
        >
          <template #icon>
            <span class="mdi mdi-menu" :style="{ color: '#212121', fontSize: '1.5rem' }"></span>
          </template>
        </a-button>

        <div class="letwecare-breadcrumb">
          <a-breadcrumb
            v-if="!device.mobile"
            class="__breadcrumb"
            :routes="breadcrumbRoutes"
          >
            <template #itemRender="{ routes, route }">
              <span v-if="routes.indexOf(route) === routes.length - 1">
                {{ route.breadcrumbName }}
              </span>
              <router-link v-else :to="route.path">
                {{ route.breadcrumbName }}
              </router-link>
            </template>
          </a-breadcrumb>

          <a-page-header
            class="__page-header"
            :style="{ height: '63px', paddingLeft: 0 }"
            :title="courseData.name"
            @back="backToCoursesPage"
          >
            <template #itemRender="{ route, params, routes, paths }">
              <span v-if="routes.indexOf(route) === routes.length - 1">{{route.breadcrumbName}}</span>
              <router-link v-else :to="paths.join('/')">{{route.breadcrumbName}}</router-link>
            </template>

            <template #subTitle v-if="!device.mobile">
              教練：{{ coachesData.map((elem) => elem.name ).join(",") }}
            </template>
          </a-page-header>
        </div>
      </a-col>

      <a-col :lg="12" :xs="3" align="right">
        <div :style="{ marginTop: '8px' }" v-if="!device.mobile">
          <a-button
            class="mr-3"
            v-if="authStore.info.role > 1"
            @click="toCourseInstantSurveyPage"
          >
            即時調查
          </a-button>
          <a-button
            class="mr-3"
            v-if="authStore.info.role > 1"
            @click="toCourseAnnouncementPage"
          >
            班級公告
          </a-button>
          <a-button
            class="mr-3"
            v-if="authStore.info.role > 1"
            @click="toCoursePushMessagesPage"
          >
            訊息推播
          </a-button>
          <a-button
            v-if="authStore.info.role > 3"
            @click="toCourseSettingsPage"
          >
            班級設定
          </a-button>
        </div>

        <a-dropdown v-if="device.mobile">
          <template #overlay>
            <a-menu>
              <a-menu-item
                v-if="authStore.info.role > 1"
                @click="toCourseInstantSurveyPage"
                :style="{ fontSize: '1.1rem', lineHeight: '2rem' }"
              >
                即時調查
              </a-menu-item>
              <a-menu-item
                v-if="authStore.info.role > 1"
                @click="toCourseAnnouncementPage"
                :style="{ fontSize: '1.1rem', lineHeight: '2rem' }"
              >
                班級公告
              </a-menu-item>
              <a-menu-item
                v-if="authStore.info.role > 1"
                @click="toCoursePushMessagesPage"
                :style="{ fontSize: '1.1rem', lineHeight: '2rem' }"
              >
                訊息推播
              </a-menu-item>
              <a-menu-item
                v-if="authStore.info.role > 3"
                @click="toCourseSettingsPage"
                :style="{ fontSize: '1.1rem', lineHeight: '2rem' }"
              >
                班級設定
              </a-menu-item>
            </a-menu>
          </template>
          <a-button :class="device.mobile ? '' : 'mt-6'" type="text" shape="circle">
            <EllipsisOutlined :style="{ fontSize: '1.2rem' }" />
          </a-button>
        </a-dropdown>
      </a-col>
    </a-row>
  </a-layout-header>
  <!-- Layout Header -->

  <!-- Layout Nested -->
  <a-layout class="app-layout-nested">
    <!-- Content -->
    <a-layout-content class="layout-nested-content">
      <div class="content__toolbar">
        <a-row>
          <a-col :span="12">
            <a-button
              class="mt-3"
              v-if="authStore.info.role > 2"
              type="primary"
              @click="openCreateLessionModal" 
            >
              <template #icon><PlusOutlined /></template>新增課堂
            </a-button>
          </a-col>

          <a-col :span="12" align="right">
            <a-tooltip placement="topLeft">
              <template #title>
                <span>切換顯示</span>
              </template>
              <a-button class="mt-2" type="text" shape="circle" size="large" @click="toggleViewMode">
                <template #icon v-if="viewMode == 'list'"><AppstoreOutlined /></template>
                <template #icon v-if="viewMode == 'calendar'"><CalendarOutlined /></template>
              </a-button>
            </a-tooltip>
          </a-col>
        </a-row>
      </div>

      <div class="content__body">
        <template v-if="viewMode == 'list'">
          <ListView
            :data="lessionsData"
            :upcomingData="upcomingData"
          />
        </template>

        <template v-if="viewMode == 'calendar'">
          <CalendarView
            :data="lessionsData"
            @updateData="getCourseData(), getLessionsData()"
            @openCreateLessionModal="handleOpenCreateLessionModal"
          />
        </template>
      </div>
    </a-layout-content>
    <!-- Content -->

    <!-- Sider -->
    <a-layout-sider
      class="layout-nested-sider"
      v-if="!device.mobile"
    >
      <a-collapse
        class="ant-collapse-ghost"
        v-model:activeKey="assistantCollapseActiveKey"
      >
        <a-collapse-panel :header="`助教 ${assistantsData.length}`" key="1">
          <a-list :data-source="assistantsData" :bordered="false">
            <template #renderItem="{ item }">
              <a-button type="text" style="height:40px; width:95%; text-align:left;">
                <a-avatar
                  :src="item.avatar!=null ? item.avatar.uri : require('@/assets/icons/avatar.png')"
                  style="margin-right:10px;"
                  size="small"
                />
                <span class="name" style="font-weight:500;">{{ item.name }}</span>
              </a-button>
            </template>
          </a-list>
        </a-collapse-panel>
      </a-collapse>

      <a-collapse
        class="ant-collapse-ghost"
        v-model:activeKey="memberCollapseActiveKey"
      >
        <a-collapse-panel :header="`學員 ${usersData.length}`" key="1">
          <a-list :data-source="usersData" :bordered="false">
            <template #renderItem="{ item }">
              <a-button
                @click="openUserReviewModal(item.uid)"
                type="text"
                :style="{ height: '40px', width: '95%', textAlign: 'left' }"
              >
                <a-avatar
                  :src="item.avatar!=null ? item.avatar.uri : require('@/assets/icons/avatar.png')"
                  style="margin-right:10px;"
                  size="small"
                />
                <span class="name" style="font-weight:500;">{{ item.name }}</span>
              </a-button>
            </template>
          </a-list>
        </a-collapse-panel>
      </a-collapse>
    </a-layout-sider>
    <!-- Sider -->
  </a-layout>
  <!-- Layout Nested -->


  <CreateLessionModal
    :visible="insertLessionModal.visible"
    :defaultStartDate="insertLessionModal.defaultStartDate"
    :defaultStartTime="courseData.start_time"
    @confirm="handleCreateLessionModalConfirm"
    @cancel="handleCreateLessionModalCancel"
  />

  <UserReviewModal
    :visible="userReviewModal.visible"
    :userId="userReviewModal.userId"
    :courseId="$route.params.course_id"
    @close="handleUserReviewModalClose"
  />
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { EllipsisOutlined, CalendarOutlined, PlusOutlined, MenuOutlined, AppstoreOutlined } from '@ant-design/icons-vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import moment from 'moment'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import api from '@/apis'
import DateUtil from '@/utils/DateUtil'
import CreateLessionModal from './components/CreateLessionModal'
import ListView from './components/ListView.vue'
import CalendarView from './components/CalendarView.vue'
import UserReviewModal from '@/components/App/User/UserReviewModal.vue'

export default defineComponent({
  components: {
    CreateLessionModal,
    ListView,
    CalendarView,
    UserReviewModal,
    EllipsisOutlined,
    CalendarOutlined,
    PlusOutlined,
    MenuOutlined,
    AppstoreOutlined,
  },
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();

    /* Store */
    const authStore = useAuthStore();
    const appStore = useAppStore();

    /* Data */
    const courseData = ref({});
    const usersData = ref([]);
    const coachesData = ref([]);
    const assistantsData = ref([]);
    const lessionsData = ref([]);
    const upcomingData = ref({});

    /* View Mode */
    const viewMode = ref('list');
    const toggleViewMode = () => {
      if (viewMode.value == 'list') viewMode.value = 'calendar';
      else if (viewMode.value == 'calendar') viewMode.value = 'list';
    }

    /* Api */
    const getCourseData = (async () => {
      courseData.value = {};
      
      const response = await api.v1.course.findOne(route.params.course_id);
      courseData.value = response.data.result;
      console.log(courseData.value);
    });

    const getLessionsData = (async () => {
      lessionsData.value = [];
      
      const response = await api.v1.lession.list({
        course_id: route.params.course_id
      });

      response.data.result.forEach(item => {
        const dateRes = DateUtil.formalizeDate(item.start_date);
        item.start_datetime_formated = dateRes;
        lessionsData.value.push(item);

        // Upcoming Lession Data
        if (Object.keys(upcomingData.value).length == 0) {
          // 計算從今天起計算，最靠近的日期
          if (moment().isBefore(`${item.start_date} 23:59:59`)) {
            upcomingData.value = item;
          }
        }
      });
    });

    const getMembers = (async (memberType) => {
      const response = await api.v1.course.findMembers(memberType, {
        course_id: route.params.course_id
      });

      if (memberType == 'user') usersData.value = response.data.result;
      else if (memberType == 'coach') coachesData.value = response.data.result;
      else if (memberType == 'assistant') assistantsData.value = response.data.result;
    });

    /* Routes breadcrumb */
    const breadcrumbRoutes = ref([
      {
        path: '/app/courses',
        breadcrumbName: '班級管理'
      }
    ]);

    /* Mounted */
    onMounted(async () => {
      await getCourseData();
      await getLessionsData();
      await getMembers('user');
      await getMembers('coach');
      await getMembers('assistant');

      // set routes breadcrumb
      breadcrumbRoutes.value.push({
        path: `/app/course/${ route.params.course_id }`,
        breadcrumbName: courseData.value.name
      });
    });

    return {
      device,

      /* Store */
      authStore,
      appStore,
      
      /* Routes breadcrumb */
      breadcrumbRoutes,

      /* Data */
      courseData,
      coachesData,
      assistantsData,
      usersData,
      lessionsData,
      upcomingData,

      /* View Mode */
      viewMode,
      toggleViewMode,

      /* Api */
      getCourseData,
      getLessionsData,
      getMembers,

      /* Sider collapse controller */
      assistantCollapseActiveKey: ref('1'),
      memberCollapseActiveKey: ref('1')
    }
  },
  data() {
    return {
      /* Insert Lession Modal */
      insertLessionModal: {
        visible: false,
        defaultStartDate: null
      },

      /* User Review Modal */
      userReviewModal: {
        visible: false,
        userId: ''
      }
    }
  },
  methods: {
    /* router */
    backToCoursesPage() {
      this.$router.push({ name: 'AppCourses' });
    },
    toCourseSettingsPage() {
      this.$router.push({
        name: 'AppCourseSettingsPage',
        params: {
          course_id: this.$route.params.course_id
        }
      });
    },
    toCourseInstantSurveyPage() {
      this.$router.push({ name: 'AppCourseInstantSurveyPage' });
    },
    toCourseAnnouncementPage() {
      this.$router.push({ name: 'AppCourseAnnouncementPage' });
    },
    toCoursePushMessagesPage() {
      this.$router.push({ name: 'AppCoursePushMessages' });
    },

    /* Insert Lession Modal */
    openCreateLessionModal() {
      this.insertLessionModal.defaultStartDate = null;
      this.insertLessionModal.visible = true;
    },
    handleOpenCreateLessionModal(current) {
      if (current) {
        this.insertLessionModal.defaultStartDate = current;
      }
      this.insertLessionModal.visible = true;
    },
    async handleCreateLessionModalConfirm() {
      await this.getLessionsData();
      this.insertLessionModal.visible = false;
    },
    handleCreateLessionModalCancel() {
      this.insertLessionModal.visible = false;
    },

    /* User Review Modal */
    openUserReviewModal(userId) {
      this.userReviewModal.visible = true;
      this.userReviewModal.userId = userId;
    },
    handleUserReviewModalClose() {
      this.userReviewModal.visible = false;
      this.userReviewModal.userId = '';
    }
  }
})
</script>