<template>
  <a-modal
    wrap-class-name="user-review-modal"
    :wrap-class-name="[{ 'full-modal': device.mobile }]"
    :visible="visible"
    :footer="false"
    :closable="false"
    @cancel="handleCancel"
    :style="{ height: '80vh', width: '80vw' }"
  >
    <a-row v-if="!loading">
      <!-- Sider -->
      <a-col class="modal-sidebar" :lg="6" :xs="24">
        <div class="user-info">
          <div class="info-avatar" align="center">
            <a-avatar
              :src="userData.avatar!=null ? userData.avatar.uri : require('@/assets/icons/avatar.png')"
            />
          </div>
          <div align="center">
            <span class="info-name">{{ userData.name }}</span>
            <span class="info-memberId">{{ userData.member_id }}</span>
          </div>
          <div align="center">
            <div class="info-text">
              <template v-if="userData.gender == 'male'">先生</template>
              <template v-else-if="userData.gender == 'female'">女士</template>
              · {{ calculateAge(userData.birthdate) }} 歲
            </div>
          </div>
        </div>

        <div class="user-muscles-record">
          <MuscleSelector :muscles="musclesStat" />
        </div>
      </a-col>
      <!-- Sider -->

      <!-- Body -->
      <a-col class="modal-body" :lg="18" :xs="24">
        <!-- 統計數據 -->
        <div class="mb-4">
          <a-row :gutter="24">
            <a-col :lg="6" :xs="24">
              <div class="body-card">
                <div class="card-title">
                  <span class="title-icon">
                    <span class="icon mdi mdi-chart-line-variant" :style="{ color: '#F44336' }"></span>
                  </span>
                  今日血壓
                </div>
                <div class="card-text">
                  <template v-if="generalStat.sbp || generalStat.dbp">
                    {{ generalStat.sbp }} / {{ generalStat.dbp }}
                  </template>
                  <template v-else>
                    無資料
                  </template>
                </div>
              </div>
            </a-col>

            <a-col :lg="6" :xs="24">
              <div class="body-card">
                <div class="card-title">
                  <span class="title-icon">
                    <span class="icon mdi mdi-heart-pulse" :style="{ color: '#F44336' }"></span>
                  </span>
                  今日心率
                </div>
                <div class="card-text">
                  <template v-if="generalStat.heartRate">
                    {{ generalStat.heartRate }}
                  </template>
                  <template v-else>
                    無資料
                  </template>
                </div>
              </div>
            </a-col>

            <a-col :lg="6" :xs="24">
              <div class="body-card">
                <div class="card-title">
                  <span class="title-icon">
                    <span class="icon mdi mdi-account" :style="{ color: '#F44336' }"></span>
                  </span>
                  出席率
                </div>
                <div class="card-text">
                  {{ Math.round(generalStat.attendanceCount / generalStat.totalLessionCount * 100) }} %
                </div>
              </div>
            </a-col>

            <a-col :lg="6" :xs="24">
              <div class="body-card">
                <div class="card-title">
                  <span class="title-icon">
                    <span class="icon mdi mdi-book-open-blank-variant" :style="{ color: '#F44336' }"></span>
                  </span>
                作業完成
                </div>
                <div class="card-text">
                  {{ generalStat.completedHwCount }} / {{ generalStat.totalHwCount }}
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
        <!-- 統計數據 -->

        <!-- 血壓歷史 -->
        <div class="body-chart mb-4">
          <div class="body-card">
            <div class="chart-title">血壓歷史</div>
            <LineChart
              :chartData="bloodPressureChartData"
              :options="chartOptions"
              :style="{ height: '300px' }"
            />
          </div>
        </div>
        <!-- 血壓歷史 -->

        <!-- 心率歷史 -->
        <div class="body-chart">
          <div class="body-card">
            <div class="chart-title">心率歷史</div>
            <LineChart
              :chartData="heartRateChartData"
              :options="chartOptions"
              :style="{ height: '300px' }"
            />
          </div>
        </div>
        <!-- 心率歷史 -->

        <!-- 運動紀錄 -->
        <div v-if="slicedTrainingRecordsData.length">
          <a-row
            class="training-action-card-list"
            :gutter="16"
          >
            <a-col
              v-for="item in slicedTrainingRecordsData"
              :span="12"
            >
              <div class="training-action-card">
                <a-row>
                  <a-col :span="6">
                    <div class="card-image">
                      <TrainingIcon :actionName="item.action_name" :height="45" />
                    </div>
                  </a-col>

                  <a-col :span="18">
                    <div class="card-title">{{ item.action_name }}</div>
                    <a-tag
                      v-if="item.setList.length"
                      class="card-tag"
                    >
                      {{ item.setList[item.setList.length-1] }}
                      <span style="font-size:0.5rem">組</span>
                    </a-tag>
                    <a-tag
                      v-if="item.repList.length"
                      class="card-tag"
                    >
                      {{ item.repList[item.repList.length-1] }}
                      <span style="font-size:0.5rem">下</span>
                    </a-tag>
                    <a-tag
                      v-if="item.weightList.length"
                      class="card-tag"
                      color="warning"
                    >
                      {{ item.weightList[item.weightList.length-1] }}
                      <span style="font-size:0.5rem">公斤</span>
                    </a-tag>
                  </a-col>

                  <a-col
                    :span="18" :offset="6"
                    :style="{ marginTop: '12px' }"
                  >
                    <template v-if="item.weightList.length">
                      <LineChart
                        :chartData="item.chartData"
                        :options="item.chartOption"
                        :style="{ height: '80px' }"
                      />
                    </template>
                    
                    <template v-else>
                      <div :style="{ color: '#BDBDBD', height: '75px', lineHeight: '75px', textAlign: 'center' }">
                        沒有重量紀錄資料
                      </div>
                    </template>
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>

          <div align="center">
            <a-button
              @click="expandMoreTrainingRecordsFlag = !expandMoreTrainingRecordsFlag"
              type="text"
              :style="{ color: '#424242' }"
            >
              <template v-if="expandMoreTrainingRecordsFlag">
                <span class="icon mdi mdi-chevron-up mr-3" :style="{ fontSize: '1rem' }" /> 只顯示前幾項
              </template>
              <template v-else>
                <span class="icon mdi mdi-chevron-down mr-3" :style="{ fontSize: '1rem' }" /> 查看所有訓練紀錄
              </template>
            </a-button>
          </div>
        </div>

        <div v-else>
          <a-empty
            class="mt-10"
            :image="simpleImage"
            description="沒有訓練數據"
          />
        </div>
        <!-- 運動紀錄 -->
      </a-col>
      <!-- Body -->
    </a-row>

    <div v-else>
      <div :style="{ textAlign: 'center', height: '70vh', lineHeight: '70vh' }">
        <a-spin
          :indicator="indicator"
          tip="努力載入中"
        />
      </div>
    </div>
  </a-modal>
</template>

<script>
import { defineComponent, computed, ref, h } from 'vue'
import moment from 'moment'
import { LoadingOutlined } from '@ant-design/icons-vue'
import { LineChart } from 'vue-chart-3'
import { createDeviceDetector } from 'next-vue-device-detector'
import { Chart, registerables } from 'chart.js'
import api from '@/apis'
import TrainingIcon from '@/components/ui/TrainingIcon'
import MuscleSelector from '@/components/ui/MuscleSelector'

Chart.register(...registerables);

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    courseId: {
      type: String,
      required: false
    }
  },
  components: {
    LoadingOutlined,
    LineChart,
    TrainingIcon,
    MuscleSelector
  },
  setup() {
    const device = createDeviceDetector();

    /* Data */
    const userData = ref({});
    const rollcallsData = ref([]);
    const userInstantSurveyReplyData = ref({});
    const courseData = ref({});

    // TODO:
    const traingRecordCourseValue = ref({});
    const trainingRecordsData = ref([]);

    /* Statistic */
    // generalStat
    const initialGeneralStat = {
      sbp: null, // 收縮壓
      dbp: null, // 舒張壓
      heartRate: null, // 心率
      attendanceCount: 0, // 簽到次數
      totalLessionCount: 0, // 所有課堂
      completedHwCount: 0, // 完成作業數
      totalHwCount: 0 // 所有作業數
    }
    const generalStat = ref({ ...initialGeneralStat });

    // musclesStat
    const initialMusclesStat = {
      // 背部
      dorsum: 0,
      legs: 0,
      glutes: 0,
      // 前部
      arms: 0,
      pectorals: 0,
      abdomen: 0
    }
    const musclesStat = ref({ ...initialMusclesStat });
    
    // bpHistory
    const initialBpHistory = {
      dates: [],
      sbpData: [],
      dbpData: [],
      hrData: []
    }
    const bpHistory = ref({ ...initialBpHistory });
    const initBpHistoryState = () => {
      bpHistory.value = {
        dates: [],
        sbpData: [],
        dbpData: [],
        hrData: []
      }
    }

    // init stat data
    const initStatisticState = () => {
      generalStat.value = { ...initialGeneralStat };
      musclesStat.value = { ...initialMusclesStat };
      bpHistory.value.value = { ...initialBpHistory };
    }

    const calculateRollcall = () => {
      const lessionPreData = rollcallsData.value.filter(item => item.type == 'lession_pre');
      // const lessionPostData = rollcallsData.value.filter(item => item.type == 'lession_post');
      
      if (!lessionPreData.length) return

      // 統計 lession_pre
      // generalStat.value.sbp = lessionPreData[lessionPreData.length-1].contents[0].value;
      // generalStat.value.dbp = lessionPreData[lessionPreData.length-1].contents[1].value;
      // generalStat.value.heartRate = lessionPreData[lessionPreData.length-1].contents[2].value;
      generalStat.value.attendanceCount = lessionPreData.length;

      // 統計 bpHistory
      rollcallsData.value.forEach(item => {
        // 日期
        let dateLabel = moment(item.lession.start_date).format('MM/DD');
        if (item.type === 'lession_pre') {
          dateLabel += '(課前)';
        } else if (item.type === 'lession_post') {
          dateLabel += '(課後)';
        }

        // 收縮壓、舒張壓、心率
        item.contents.forEach(content => {
          if (content.name === '收縮壓') {
            bpHistory.value.sbpData.push(content.value);
            bpHistory.value.dates.push(dateLabel);

            generalStat.value.sbp = content.value;
          }

          if (content.name === '舒張壓') {
            bpHistory.value.dbpData.push(content.value);

            generalStat.value.dbp = content.value;
          }
          
          if (content.name === '心率') {
            bpHistory.value.hrData.push(content.value);

            generalStat.value.heartRate = content.value;
          }
        });
      });

      // lessionPreData.forEach(item => {
      //   bpHistory.value.dates.push(moment(item.lession.start_date).format('MM/DD'));
      //   bpHistory.value.sbpData.push(item.contents[0].value);
      //   bpHistory.value.dbpData.push(item.contents[1].value);
      // });

      console.log(bpHistory.value.dates)
      console.log(bpHistory.value.sbpData)
      console.log(bpHistory.value.dbpData)
      console.log(bpHistory.value.hrData)
      setBPChartData(bpHistory.value.dates, bpHistory.value.sbpData, bpHistory.value.dbpData);
      setHRChartData(bpHistory.value.dates, bpHistory.value.hrData);

      // TODO: 統計 musclesStat
      if (userInstantSurveyReplyData.value.length) {
        const muscleSoreContentValueList = userInstantSurveyReplyData.value[userInstantSurveyReplyData.value.length-1].contents[0].value;
        console.log(userInstantSurveyReplyData.value)
        muscleSoreContentValueList.forEach(item => {
          if (item == '手/手臂') {
            musclesStat.value.arms = 1;
          }
          if (item == '胸部') {
            musclesStat.value.pectorals = 1;
          }
          if (item == '腰') {
            musclesStat.value.abdomen = 1;
          }
          if (item == '背') {
            musclesStat.value.dorsum = 1;
          }
          if (item == '臀部') {
            musclesStat.value.glutes = 1;
          }
          if (item == '大腿/小腿') {
            musclesStat.value.legs = 1;
          }
        });
      }

      console.log(generalStat.value)
      console.log(bpHistory.value.value)
      console.log(musclesStat.value)
    }

    /* Training Records */
    const expandMoreTrainingRecordsFlag = ref(false);
    const slicedTrainingRecordsData = computed(() => {
      if (trainingRecordsData.value.length === 0) return []

      if (!expandMoreTrainingRecordsFlag.value) {
        if (trainingRecordsData.value.length > 4) {
          return trainingRecordsData.value.slice(0, 4);
        }
      }

      return trainingRecordsData.value
    });

    /* Api */
    const getUserData = (async (userId) => {
      try {
        const response = await api.v1.user.findOne(userId);
        userData.value = response.data.result;
        console.log(userData.value);
      } catch (err) {
        console.log(err);
      }
    });

    const getUserRolllcallsData = (async (userId, courseId) => {
      try {
        const response = await api.v1.user.findUserRollcalls(userId, {
          course_id: courseId
        });
        rollcallsData.value = response.data.result;
        console.log(rollcallsData.value);
      } catch (err) {
        console.log(err);
      }
    });

    const getCourseData = (async (courseId) => {
      const response = await api.v1.course.findOne(courseId);
      courseData.value = response.data.result;

      // 計算所有課堂數 & 作業數
      generalStat.value.totalLessionCount = 0
      courseData.value.lessions.forEach(lession => {
        if (moment().isAfter(lession.start_date)) generalStat.value.totalLessionCount += 1;
      });
      // generalStat.value.totalLessionCount = courseData.value.lessions.length;
      generalStat.value.totalHwCount = 0;
      courseData.value.lessions.forEach(lession => {
        generalStat.value.totalHwCount += lession.homeworks.length;
      });

      console.log(courseData.value);
    });

    const getUserHomeworks = (async (userId, courseId) => {
      const response = await api.v1.user.findUserHomeworks(userId, {
        course_id: courseId
      });
      generalStat.value.completedHwCount = response.data.result.length;
      console.log(response.data.result);
    });

    const getUserCourseInstantSurveyReplyList = (async (userId, courseId) => {
      const response = await api.v1.user.getCourseInstantSurveyList(userId, courseId);
      userInstantSurveyReplyData.value = response.data.result;
      console.log(userInstantSurveyReplyData.value)

      console.log(response.data.result);
    });
    
    // TODO:
    const getTrainingRecords = (async (userId, courseId) => {
      trainingRecordsData.value = [];

      const courseResponse = await api.v1.course.findTrainingRecords(courseId);
      const userResponse = await api.v1.user.findTrainingRecord(userId, { course_id: courseId });

      // set course data
      traingRecordCourseValue.value = { ...courseData.value };

      // filter out the course which user not attend
      const filteredCourseTrainingRecords = [];
      courseResponse.data.result.forEach(record => {
        rollcallsData.value.forEach(rollcall => {
          if (rollcall.type === 'lession_pre') {
            if (record.course_id===rollcall.course_id && record.lession_id===rollcall.lession_id) {
              filteredCourseTrainingRecords.push(record);
            }
          }
        });
      });

      // combime course & user training records
      filteredCourseTrainingRecords.forEach(crItem => {
        userResponse.data.result.forEach(urItem => {
          if (crItem.id === urItem.training_record_id) {
            crItem.weight = parseInt(urItem.weight);
          }
        });
      });

      // set lession info & sort by lession start date
      const lessions = traingRecordCourseValue.value.lessions;
      const lessionStartDateMap = new Map();
      lessions.forEach(l => {
        lessionStartDateMap.set(l.id, l.start_date);
      });

      filteredCourseTrainingRecords.forEach(record => {
        if (lessionStartDateMap.has(record.lession_id)) {
          record.start_date = lessionStartDateMap.get(record.lession_id);
        }
      });

      const result = filteredCourseTrainingRecords.sort((a, b) => moment(a.start_date) - moment(b.start_date));
      console.log(result)
      
      // re-structure with action name object
      let trainingRecordObject = {};
      result.forEach(item => {
        if (!Object.keys(trainingRecordObject).includes(item.action_name)) {
          trainingRecordObject[item.action_name] = {
            action_name: item.action_name,
            repList: [],
            setList: [],
            weightList: [],
            chartData: {
              labels: [],
              datasets: [
                {
                  label: '公斤',
                  data: [],
                  borderColor: '#fa8c16',
                  backgroundColor: '#fa8c16',
                  tension: 0.4,
                  yAxisID: 'y'
                }
              ]
            },
            chartOption: {
              plugins: {
                legend: {
                  display: false
                }
              },
              scales: {
                x: {
                  ticks: {
                    display: false
                  }
                }
              }
            }
          }
        }

        trainingRecordObject[item.action_name].repList.push(item.rep);
        trainingRecordObject[item.action_name].setList.push(item.set);

        if (item.weight) {
          trainingRecordObject[item.action_name].weightList.push(item.weight);
          trainingRecordObject[item.action_name].chartData.labels.push(item.start_date);
          trainingRecordObject[item.action_name].chartData.datasets[0].data.push(item.weight);
        }
      });

      // convert trainingRecordsData from object to object-array
      Object.keys(trainingRecordObject).forEach(key => {
        trainingRecordsData.value.push(trainingRecordObject[key]);
      });
      
      console.log(trainingRecordsData.value)
    });

    /* Chart */
    const bloodPressureChartData = ref({
      labels: [],
      datasets: [
        {
          label: '收縮壓',
          data: [],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgb(255, 99, 132)',
          tension: 0.4,
          yAxisID: 'y'
        },
        {
          label: '舒張壓',
          data: [],
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgb(54, 162, 235)',
          tension: 0.4,
          yAxisID: 'y'
        }
      ]
    });

    const heartRateChartData = ref({
      labels: [],
      datasets: [
        {
          label: '心率',
          data: [],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgb(255, 99, 132)',
          tension: 0.4,
          yAxisID: 'y'
        }
      ]
    });

    const chartOptions = {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false
      },
      stacked: false,
      scales: {
        y: {
          type: 'linear',
          display: true,
          position: 'left'
        }
      }
    }

    const initChartData = () => {
      // bp chart
      bloodPressureChartData.value.labels = [];
      bloodPressureChartData.value.datasets[0].data = [];
      bloodPressureChartData.value.datasets[1].data = [];

      // hr chart
      heartRateChartData.value.labels = [];
      heartRateChartData.value.datasets[0].data = [];
    }
    
    const setBPChartData = (dates, shpData, dbpData) => {
      bloodPressureChartData.value.labels = dates;
      bloodPressureChartData.value.datasets[0].data = shpData;
      bloodPressureChartData.value.datasets[1].data = dbpData;
    }

    const setHRChartData = (dates, hrData) => {
      heartRateChartData.value.labels = dates;
      heartRateChartData.value.datasets[0].data = hrData;
    }

    return {
      device,
      
      /* Loading */
      loading: ref(true),

      /* Data */
      userData,
      rollcallsData,
      courseData,

      // training records
      expandMoreTrainingRecordsFlag,
      slicedTrainingRecordsData,

      /* Statistic */
      generalStat,
      musclesStat,
      bpHistory,
      initBpHistoryState,
      initStatisticState,
      calculateRollcall,

      /* Chart */
      bloodPressureChartData,
      heartRateChartData,
      chartOptions,
      initChartData,

      /* Api */
      getUserData,
      getUserRolllcallsData,
      getCourseData,
      getUserHomeworks,
      getUserCourseInstantSurveyReplyList,
      getTrainingRecords,

      /* Antd */
      indicator: h(LoadingOutlined, {
        style: {
          fontSize: '24px',
        },
        spin: true,
      })
    }
  },
  watch: {
    async visible() {
      if (this.visible) {
        this.loading = true;

        this.initBpHistoryState();
        this.initStatisticState();
        this.initChartData();
        await this.getUserData(this.userId);

        if (this.courseId) {
          await this.getCourseData(this.courseId);
          await this.getUserCourseInstantSurveyReplyList(this.userId, this.courseId);
          await this.getUserRolllcallsData(this.userId, this.courseId);
          await this.getUserHomeworks(this.userId, this.courseId);
          await this.getTrainingRecords(this.userId, this.courseId);
          this.calculateRollcall();
        }

        this.loading = false;

        // modal-body 區塊置頂
        const dom = document.getElementsByClassName('modal-body');
        dom[0].scrollTop = 0;
      }
    }
  },
  methods: {
    /* Utils */
    calculateAge(birthdate) {
      const ageDifMs = Date.now() - new Date(birthdate).getTime();
      const ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    
    /* Modal Func */
    handleCancel() {
      this.$emit('close');
    }
  }
})
</script>

<style lang="scss">
.user-review-modal {
  overflow: auto !important;
  
  /* md */
  @media screen and (min-width: 970px) {
    overflow: hidden !important;
  }
  
  .modal-sidebar {

  }

  .modal-body {
    max-height: unset !important;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 12px;

    /* md */
    @media screen and (min-width: 970px) {
      max-height: calc(80vh - 48px) !important;
    }
    
    .body-card {
      width: 100%;
      background-color: #FAFAFA;
      border-radius: 4px;
      padding: 20px;
      
      .card-title {
        color: #424242;
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 12px;

        .title-icon {
          display: inline-block;
          width: 28px !important;
          height: 28px !important;
          background: white;
          border-radius: 100%;
          margin-right: 4px;
          text-align: center;

          .icon {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      .card-text {
        color: #212121;
        font-size: 1.7rem;
        font-weight: 450;
        text-align: center;
      }
    }
  }

  .user-info {
    margin-bottom: 24px;

    .info-avatar {
      margin-bottom: 12px;
      
      .ant-avatar {
        width: 80px;
        height: 80px;
      }
    }

    .info-name {
      font-size: 1.6rem;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0.06rem;
      margin-right: 5px;
    }

    .info-memberId {
      color: #757575;
      font-size: .9rem;
      font-weight: 500;
    }

    .info-text {
      color: #424242;
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .body-chart {
    .chart-title {
      color: #424242;
      font-size: 1.2rem;
      font-weight: 400;
      letter-spacing: 0.05rem;
      margin-bottom: 8px;
    }
  }

  /* Training action card style */
  .training-action-card-list {
    padding: 16px 0;

    .training-action-card {
      height: 178px;
      border: 2px solid #ececec;
      // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      padding: 12px;
      margin-bottom: 16px;

      .card-image {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 8px;
      }

      .card-title {
        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: 0.05rem;
        margin-bottom: 4px;
      }

      .card-tag {
        font-size: .8rem;
      }
    }
  }
}
</style>