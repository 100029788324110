<template>
  <a-modal
    class="lession-postpone-modal"
    :visible="visible"
    :title="`${lessionInfo.name}延期`"
    okText="確認延期"
    cancelText="取消"
    :closable="false"
    :maskClosable="false"
    :confirmLoading="loading"
    @ok="confirmPostpone"
    @cancel="$emit('cancel')"
  >
    <a-alert class="mb-5" message="您正在執行課堂延期動作，此課堂後的所有課堂，會因為此課堂的延期，自動往後順延。" banner />

    <div class="modal__header">
      <div :style="{ fontWeight: 500, fontSize: '0.8rem', marginBottom: '4px', color: '#424242' }">原先課堂時間</div>
      <div>{{ lessionInfo.start_date }} {{ lessionInfo.start_time }}</div>
    </div>

    <div class="mdi mdi-chevron-double-down" :style="{ fontSize: '1.45rem', color: '#878787', textAlign: 'center', marginBottom: '20px' }" />

    <div class="modal__body">
      <div :style="{ fontWeight: 500, fontSize: '0.8rem', marginBottom: '8px', color: '#424242' }">延期後課堂時間</div>
      <a-select
        v-model:value="postponeValue"
        :options="postponeOptions"
        :disabled="loading"
        :style="{ display: 'inline-block', width: '100px', marginRight: '12px' }"
      />
      <span>{{ postponedLessionDate }} {{ lessionInfo.start_time }}</span>
    </div>
  </a-modal>
</template>

<script>
import { defineComponent } from 'vue'
import { message } from 'ant-design-vue'
import api from '@/apis'
import DateUtil from '@/utils/DateUtil'

export default defineComponent({
  props: ['visible', 'lessionInfo'],
  components: {},
  setup() {
    return {
      message
    }
  },
  data() {
    const postponeValue = 1;
    const postponeOptions = [{
      label: '延期一週',
      value: 1
    }, {
      label: '延期兩週',
      value: 2
    } , {
      label: '延期三週',
      value: 3
    }]

    return {
      loading: false,

      postponeValue,
      postponeOptions
    }
  },
  computed: {
    postponedLessionDate() {
      if (!this.lessionInfo) return null
      return DateUtil.addDay(this.lessionInfo.start_date, this.postponeValue * 7)
    }
  },
  methods: {
    async confirmPostpone() {
      this.loading = true;

      try {
        const response = await api.v1.lession.postpone(this.$route.params.course_id, this.lessionInfo.id, {
          days: this.postponeValue * 7
        });

        this.message.success(`延期${this.lessionInfo.name}成功，順延了${response.data.result.postponed_count}個課堂`);

        this.$emit('complete');
      } catch (error) {
        console.log(error);
        this.message.error('延期課堂發生錯誤，請稍後再試');
      } finally {
        this.loading = false;
      }
    }
  }
})
</script>

<style lang="scss">
.lession-postpone-modal {
  .modal__header {
    font-size: 1rem;
    margin-bottom: 12px;
  }

  .modal__body {
    font-size: 1rem;
  }
}
</style>