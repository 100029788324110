<template>
  <a-card :style="{ borderRadius: '4px' }">
    <a-calendar
      class="cv-calendar"
      v-model:value="calendarValue"
      @select="openCreateLessionModal"
      @panelChange="handlePanelChange"
      mode="month"
      locale="zh_TW"
    >
      <template #dateCellRender="{ current }">
        <template v-for="lession in data">
          <span v-if="DateUtil.diff(dayjs(current).format('YYYY-MM-DD'), lession.start_date) == 0">
            <a-popover
              v-model:visible="lession.visible"
              @click.prevent="lession.visible=true"
              :title="lession.name"
              trigger="click"
            >
              <template #content>
                <a-button
                  class="mb-2"
                  @click="toLessionPage(lession.id)"
                  :style="{ display: 'block', width: '100%' }"
                >查看課堂</a-button>
                <a-button
                  class="mb-2"
                  @click="openPostponeModal(lession)"
                  :style="{ display: 'block', width: '100%' }"
                >延期</a-button>
              </template>

              <a-tag
                :color="statusColor(current, lession.start_date)"
                :style="{ whiteSpace: 'pre-line', fontSize: '.9rem', padding: '6px' }"
              >{{ lession.name }}</a-tag>
            </a-popover>
          </span>
        </template>
      </template>
    </a-calendar>
  </a-card>

  <PostponeModal
    :visible="postponeModal.visible"
    :lessionInfo="postponeModal.lessionInfo"
    @complete="handlePostponeModalComplete"
    @cancel="handlePostponeModalClose"
  />
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-tw'
import { useAuthStore } from '@/store/auth'
import DateUtil from '@/utils/DateUtil'
import PostponeModal from '@/components/App/Lession/PostponeModal'

dayjs.locale('zh-tw');

export default defineComponent({
  props: ['data'],
  components: {
    PostponeModal
  },
  setup(props) {
    const authStore = useAuthStore();

    const calendarValue = ref();
    const panelChangeLock = ref(false);

    onMounted(() => {
      props.data.forEach(lession => {
        lession.visible = false;
      });
    });

    return {
      authStore,

      calendarValue,
      panelChangeLock,

      dayjs,
      DateUtil
    }
  },
  data() {
    return {
      /* Postpone Modal */
      postponeModal: {
        visible: false,
        lessionInfo: {}
      },
    }
  },
  methods: {
    /* Router */
    toLessionPage(lession_id) {
      this.$router.push({
        name: 'AppCourseLessionPage',
        params: {
          course_id: this.$route.params.course_id,
          lession_id: lession_id
        }
      });
    },

    /* Course Status Color */
    statusColor(current, start_date) {
      const diffDay = DateUtil.diff(start_date, current);
      
      if (diffDay < 0) {
        return '#424242'
      } else if (diffDay >= 0 && diffDay <6) {
        return 'red'
      } else if (diffDay > 7) {
        return '#108ee9'
      }
    },

    /* Calendar Funcs */
    openCreateLessionModal(current) {
      if (this.panelChangeLock) return

      for (let i=0; i<this.data.length; i++) {
        const item = this.data[i];
        if (DateUtil.diff(dayjs(current).format('YYYY-MM-DD'), item.start_date) == 0) return
      }
      
      this.$emit('openCreateLessionModal', current);
    },
    handlePanelChange(e) {
      this.panelChangeLock = true;

      setTimeout(() => {
        this.panelChangeLock = false;
      }, 500)
    },

    /* Postpone Modal */
    openPostponeModal(lession) {
      lession.visible = false;

      this.postponeModal.lessionInfo = lession;
      this.postponeModal.visible = true;
    },
    handlePostponeModalComplete() {
      this.$emit('updateData');
      this.postponeModal.visible = false;
    },
    handlePostponeModalClose() {
      this.postponeModal.visible = false;
    },
  }
})
</script>

<style lang="scss" scoped>
:deep(.cv-calendar) {
  .ant-picker-calendar-header .ant-radio-group {
    display:none
  }
}
</style>