<template>
  <a-modal
    :visible="visible"
    :wrap-class-name="[{ 'full-modal': device.mobile }]"
    title="新增課堂"
    okText="確認新增課堂"
    cancelText="取消"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <!-- Form -->
    <a-form
      :model="formState"
      layout="vertical"
      autocomplete="off"
    > 
      <a-row>
        <a-col :span="24">
          <a-form-item
            label="課堂名稱"
            name="name"
            :rules="[{ required: true, message: '請輸入課堂名稱' }]"
          >
            <a-input v-model:value="formState.name" size="large" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="課堂說明"
            name="description"
            :rules="[{ required: true, message: '請輸入課堂說明' }]"
          >
            <a-input
              v-model:value="formState.description"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="上課日期"
            name="start_date"
            :rules="[{ type: 'string', required: true, message: '請輸入上課日期' }]"
          >
            <a-date-picker
              v-model:value="tempStartDate"
              :show-time="{ format: 'YYYY-MM-DD' }"
              @change="onChangeStartDate"
              format="YYYY-MM-DD"
              placeholder="選擇上課日期"
              size="large"
              :style="{ width: '100%' }"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item
            label="上課時間"
            name="start_time"
            :rules="[{ type: 'string', required: true, message: '請輸入上課時間' }]"
          >
            <a-time-picker
              v-model:value="tempStartTime"
              :show-time="{ format: 'HH:mm' }"
              @change="onChangeStartTime"
              format="HH:mm"
              placeholder="選擇上課時間"
              size="large"
              :style="{ width: '100%' }"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <!-- Form -->
    
    <a-typography-text
      type="secondary"
      :style="{ display: 'block', textAlign: 'right' }"
    >詳細資訊可以在新增課堂後進行設定</a-typography-text>
  </a-modal>
</template>

<script>
import { defineComponent, ref, reactive } from 'vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import api from '@/apis'

export default defineComponent({
  props: ['visible', 'defaultStartDate', 'defaultStartTime'],
  components: {},
  setup() {
    const device = createDeviceDetector();

    /* Form State */
    const initialState = {
      name: '',
      description: '',
      start_date: '',
      start_time: ''
    }
    const formState = reactive({ ...initialState });
    function resetForm() {
      Object.assign(formState, initialState);
    }

    /* Start Datetime Picker */
    const tempStartDate = ref('');
    const tempStartTime = ref('');
    const onChangeStartDate = (value) => {
      formState.start_date = dayjs(value).format('YYYY-MM-DD');
    }
    const onChangeStartTime = (value) => {
      formState.start_time = dayjs(value).format('HH:mm');
    }

    return {
      device,
      
      /* Form State */
      formState,
      resetForm,
      
      /* Start Datetime Picker */
      tempStartDate,
      tempStartTime,
      onChangeStartDate,
      onChangeStartTime
    }
  },
  watch: {
    visible() {
      if (this.visible) {
        if (this.defaultStartDate) {
          this.tempStartDate = dayjs(this.defaultStartDate, 'YYYY-MM-DD');
          this.onChangeStartDate(this.tempStartDate);
        }
        
        this.tempStartTime = dayjs(this.defaultStartTime, 'HH:mm');
        this.onChangeStartTime(this.tempStartTime);
      }
    }
  },
  methods: {
    async handleOk() {
      /* create lession */
      try {
        await api.v1.lession.create({
          course_id: this.$route.params.course_id,
          ...this.formState
        });
        console.log(this.formState)
        message.success('創建課堂成功');
      } catch (error) {
        message.error('創建課堂發生錯誤');
        console.log(error);
      }

      this.resetForm();
      this.tempStartDate = '';
      this.tempStartTime = '';
      this.$emit('confirm');
    },
    async handleCancel() {
      this.$emit('cancel');

      // reset data
      this.resetForm();
      this.tempStartDate = '';
      this.tempStartTime = '';
    }
  }
})
</script>